import React from 'react'
import {Box , Center , Text , Image, Link } from '@chakra-ui/react'
import victor from '../../assets/victor.jpg';
import salah from '../../assets/salah.jpg';
import youssef from '../../assets/youssef.PNG';
import julie from '../../assets/julie.jpg';
import {AiOutlineLinkedin} from 'react-icons/ai';

const team = [

    {
        position: 'Founder',
        name: 'Youssef Rais',
        img: youssef,
        profile: 'https://www.linkedin.com/in/youssef-rais-4b8608110/',
        experience: 'Ex Accenture Software Engineer with over 4 years of experience, worked on different technologies such as blockchain and finance.'
    },
  

   
    {
        position: 'UX/UI Designer',
        name: 'Yulia Ustimenko',
        img: julie,
        profile: 'https://www.linkedin.com/in/julie-mendeleve-a12a8a237/',
        experience: 'Experienced UX/UI Designer worked in the creative industry for over 3 years.'
    },

    {
        position: 'Game Developer',
        name: 'Victor Pyagai',
        img: victor,
        profile: 'https://www.linkedin.com/',
        experience: 'Game Developer with over 2 years of experience, developed many cross platform games with Three.js, Unity game engine and Unreal Engine.'
    },
    {
        
        position: 'Tech Lead',
        name: 'Salah Boukadi',
        img: salah,
        profile: 'https://www.linkedin.com/in/salah-eddine-boukadi-21ba98169/',
        experience: "Software Engineer with 3 years of experience worked on different projects in the tech industry."
    }



]

function Section() {
  return (
    <>
        {team.map(({position, name, img , experience,profile}) => (
            <Box width={["100%","100%","25%","25%"]} pr={[5,5,0,0]} ml={[0,0,0,5]} mr={[0,0,7,7]}>
                <Image fit="cover" borderRadius="10px" src={img} width={["100%","100%","100%","100%"]} alt="logo" height={["12.375rem","14.625rem","14rem","17rem", "17rem","22rem"]}/>
                <Center d="flex">
                    <Text as="p" color="brand.100" fontSize="1.0rem" pt={[4,4,6,6]} >
                        {position} 

                    </Text>
                    <Box pt={[4,4,6,6]} pl={3} cursor="pointer">
                        <Link textDecoration="none"  href={profile} isExternal>
                            <AiOutlineLinkedin size="1.5rem" fill="white" />
                        </Link>
                    </Box>
                    
                </Center>
                <Center>
                    <Text fontSize={["1rem","1.1rem","1.1rem","1.3rem"]} fontWeight="bold" lineHeight="2.2rem" color="brand.900" pt={2} >
                        {name}
                    </Text>
                </Center>
                    <Text as="p" color="brand.100" fontSize="1.0rem" pt={6} pb={[10,20,20,20]} >
                   {experience}

                    </Text>
            </Box>


        ))}

    </>
  )
}

export default Section
